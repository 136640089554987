import axios from 'axios';
import Cookies from 'js-cookie';
// axios.defaults.baseURL = '1asdasdsa';
const config = {
    udata: Cookies.get('usertoken'),
    //locale run
    // baseURL: 'http://192.168.200.104:5000/v1/',
    // server run
    baseURL: 'https://api.ileading360.com/v1/',
    // baseURL: 'https://ef3d-217-218-23-254.ngrok-free.app/v1/',
    axiosHandler: (validate) => {
        return axios.create({
            baseURL: config.baseURL,
            headers: {
                Authorization: 'Bearer' + ' ' + config.udata
                // 'ngrok-skip-browser-warning': true
                // validation: validate
            }
        });
    }
};

export default config;
