import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import api from 'config/api';
import Cookies from 'js-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/errorFallBack';

let PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const [Authed, setAuthed] = useState(customization['Authed']);
    const [verified, setVerified] = useState(true);

    const handleError = useCallback((error, info) => {
        console.error('Error caught by ErrorBoundary:', error);
        console.error('Error details:', info);
    }, []);

    const getGeoInfo = () => {
        axios
            .get('https://ipapi.co/json')
            .then((response) => {
                let country = response.data.country;
                const res_currency = country == 'US' || country == 'NL' ? '$' : '£';
                dispatch({ type: 'COUNTRY', country });
                dispatch({ type: 'CURRENCY', res_currency });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        api.getUser()
            .then((res) => {
                const user_detail = res.data.userInfo;
                dispatch({ type: 'USER_DETAIL', user_detail });
            })
            .catch((err) => {
                if (err?.response?.status === 403 && !err?.response?.data?.verify) {
                    // Cookies.remove('usertoken');
                    navigate('/register?step=2');
                }
                console.log(err);
            });
        getGeoInfo();
    }, []);

    return !Cookies.get('usertoken') ? (
        <Navigate to={'/login'} replace={true} />
    ) : (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
            {children}
        </ErrorBoundary>
    );
};

export default PrivateRoute;
